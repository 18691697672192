.orderTable {
    background-color: lightsteelblue;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    border-radius: 15px;
    padding: 20px;
}

.orderDetailsRow {
    display: flex;
    align-items: center;
    border-bottom: 1px solid black;
}

.orderDetailName {
    font-size: 16px;
    font-weight: 700;
    font-family: Arial, Helvetica, sans-serif;
    color: black;
    width: 50%;
    margin: 10px 0px;
}

.orderDetails {
    font-size: 14px;
    font-weight: 500;
    font-family: Arial, Helvetica, sans-serif;
    color: black;
    width: 50%;
    margin: 10px 0px;

}